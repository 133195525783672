<template>
  <v-row align="center" justify="center" no-gutters>
    <v-col cols="12" xs="8">
      <v-carousel
        v-if="images.length"
        height="450"
        reverse-transition="fade-transition"
        transition="fade-transition"
        cycle
        interval="5000"
        hide-delimiter-background
        show-arrows-on-hover
        elevation="5"
      >
        <v-carousel-item
          v-for="(item, i) in images"
          :key="i"
          :src="item.downloadUrl"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>
      <v-alert v-else type="info" border="left" colored-border elevation="2">
        No images available at the moment.
      </v-alert>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
import { db } from "@/firebase"; // Ensure this imports your Firebase configuration

export default {
  data() {
    return {
      images: [],
      loading: true,
    };
  },
  methods: {
    async fetchImages() {
      try {
        const querySnapshot = await db
          .collection("Images")
          .orderBy("Priority", "desc")
          .get();
        this.images = querySnapshot.docs.map((doc) => doc.data());

        // Sort by date if priorities are equal
        this.images.sort((a, b) => {
          if (a.Priority === b.Priority) {
            return new Date(b.Date) - new Date(a.Date);
          }
          return b.Priority - a.Priority;
        });
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchImages();
  },
};
</script>

<style scoped>
.v-alert {
  margin-top: 20px;
}
.v-progress-circular {
  margin-top: 20px;
}
</style>
